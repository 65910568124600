
const countriesHide2 = [
  'Jamaica',
  'Burkina Faso',
  'Ghana',
  'Morocco',
  'Dem. Rep. Congo',
  'Nicaragua',
  'Venezuela',
  'Suriname',
  'Argentina',
  'Mali',
  'Uzbekistan',
  'Gabon',
  'Mozambique',
  'Nigeria',
  'Chad',
  'S. Sudan',
  'Kuwait',
  'Romania',
  'Mauritania',
  'Estonia',
  'Bangladesh',
  'Jordan',
  'Georgia',
  'Turkey',
  'Bulgaria',
  'Denmark',
  'Turkmenistan',
  'Kyrgyzstan',
  'Tajikistan',
  'Afghanistan',
  'eSwatini',
  'Ethiopia',
  'Eritrea',
  'Netherlands',
  'Luxembourg',
  'Poland',
  'Lithuania',
  'Austria',
  'Saudi Arabia',
  'Croatia',
  'Cyprus',
  'Djibouti',
  'Uganda',
  'Malawi',
  'Ecuador',
  'Belarus',
  'Cuba',
  'Laos',
  'North Korea',
  'South Korea',
  'Nepal',
  'Sri Lanka',
  'Timor-Leste',
  'Malaysia',
  'Myanmar',
  'Papua New Guinea',
  'Vanuatu',
  'New Caledonia',
  'Niger',
  'Fr. S. Antarctic Lands',
  'Falkland Is.',
  'Liberia',
  'Botswana',
  'Paraguay',
  'Solomon Is.',
];
const countriesHide4 = [
  'Haiti',
  'Ireland',
  'Switzerland',
  'Lebanon',
  'Panama',
  'Syria',
  'Albania',
  'Qatar',
  'Czechia',
  'Honduras',
  'Moldova',
  'Slovenia',
  'Montenegro',
  'Zimbabwe',
  'Hungary',
  'Belgium',
  'Benin',
  'Bhutan',
  'Guatemala',
  'Slovakia',
  'Togo',
  'Cambodia',
  'Vietnam',
  'Namibia',
  'Sierra Leone',
  'Brunei',
  'Guinea',
  'Guinea-Bissau',
  'Eq. Guinea',
  'Central African Rep.',
  'Congo',
  'Somaliland',
  'Kenya',
  'Zambia',
  'Portugal',
  'Serbia',
  'Trinidad and Tobago',
  'United Arab Emirates',
];
const countriesHide6 = [
  'Guyana',
  'Gambia',
  'Rwanda',
  'Burundi',
  'Lesotho',
  'Macedonia',
  'El Salvador',
  'Côte d\'Ivoire',
];
const countriesHide10 = ['Dominican Rep.', 'Bosnia and Herz.', 'Kosovo', 'N. Cyprus', 'Israel', 'Palestine', 'Azerbaijan'];

export { countriesHide2, countriesHide4, countriesHide6, countriesHide10 };